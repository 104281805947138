<div id="testimonials" class="testimonial-style-three pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Peoples Quotes About Me</h2>
            <p>App Store and Google Play Store gift cards are interchangeable, so no matter which one you received  you can use the credit for apps.</p>
        </div>
    </div>
    <div class="testimonial-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-sm-10 offset-sm-1">
                    <div class="testimonial-text-slider slider-for text-center">
                        <owl-carousel-o [options]="testimonialTextSlides">
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/personal-portfolio/testimonial/1.png" alt="client image">
                                    <h2>John Doe</h2>
                                    <span>Treaveller</span>
                                    <p>I am really enjoying this with him. I was surprised that he did complete all my recommendataion in time. Every time I will suggest him to do work with John.</p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/personal-portfolio/testimonial/2.png" alt="client image">
                                    <h2>Alice</h2>
                                    <span>UX Designer</span>
                                    <p>I am really enjoying this with him. I was surprised that he did complete all my recommendataion in time. Every time I will suggest him to do work with John.</p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/personal-portfolio/testimonial/3.png" alt="client image">
                                    <h2>Caroline</h2>
                                    <span>analyst</span>
                                    <p>I am really enjoying this with him. I was surprised that he did complete all my recommendataion in time. Every time I will suggest him to do work with John.</p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/personal-portfolio/testimonial/4.png" alt="client image">
                                    <h2>Eddey</h2>
                                    <span>Treaveller</span>
                                    <p>I am really enjoying this with him. I was surprised that he did complete all my recommendataion in time. Every time I will suggest him to do work with John.</p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/personal-portfolio/testimonial/5.png" alt="client image">
                                    <h2>William</h2>
                                    <span>SEO Expert</span>
                                    <p>I am really enjoying this with him. I was surprised that he did complete all my recommendataion in time. Every time I will suggest him to do work with John.</p>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>   
                </div>
            </div>
        </div>
    </div>
</div>