import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-why-choose',
  templateUrl: './app-why-choose.component.html',
  styleUrls: ['./app-why-choose.component.scss']
})
export class AppWhyChooseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
