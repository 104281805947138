<div class="how-use pt-100">
    <div class="container">
        <div class="section-title">
            <span>How To Use?</span>
            <h2>4 Simple Steps to Follow</h2>
            <p>Here is a tutorial on how you can operate this app easily and effortlessly reserve your tickets for your awaited trip. Follow the given instruction.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-one">
                            <span>1</span>
                            <i class="flaticon-coding"></i>
                            <h3>Installation First</h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p>Easily install the app in your device from the app store or download it online.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p>Give your identification and information in the app to help us recognize you.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-two">
                            <span>2</span>
                            <i class="flaticon-settings"></i>
                            <h3>Set Your Profile</h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-three">
                            <span>3</span>
                            <i class="flaticon-map"></i>
                            <h3>Set Destination</h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p>Type your destination and also put the date of when you want to leave for the destination.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p>Finally, pay your bill and confirm your booking for the ticket and the ticket will be all yours.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-four">
                            <span>4</span>
                            <i class="flaticon-ticket"></i>
                            <h3>Book Your Ticket</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 offset-lg-2 wow fadeInUp" data-wow-duration="1s">
                <div class="how-use-slider">
                    <owl-carousel-o [options]="howToUseSlider">
                        <ng-template carouselSlide>
                            <div class="how-use-img">
                                <img src="assets/img/app-landing/1.png" alt="iphone">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="how-use-img">
                                <img src="assets/img/app-landing/1.png" alt="iphone">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="how-use-img">
                                <img src="assets/img/app-landing/1.png" alt="iphone">
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>