<div id="screenshots" class="screenshots-section ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <span>Screenshots</span>
            <h2>Have Look at Our App Interface</h2>
            <p>Grab a look at our outstanding and stunning App Interfaces which is easy to use and very easily manageable.</p>
        </div>
        <div class="screenshot-slider">
            <owl-carousel-o [options]="screenshotSlider">
                <ng-template carouselSlide>
                    <div class="screenshoot-img">
                        <img src="assets/img/app-landing/1.png" alt="app-screenshot">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="screenshoot-img">
                        <img src="assets/img/app-landing/2.png" alt="app-screenshot">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="screenshoot-img">
                        <img src="assets/img/app-landing/3.png" alt="app-screenshot">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="screenshoot-img">
                        <img src="assets/img/app-landing/4.png" alt="app-screenshot">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="screenshoot-img">
                        <img src="assets/img/app-landing/5.png" alt="app-screenshot">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="screenshoot-img">
                        <img src="assets/img/app-landing/3.png" alt="app-screenshot">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
        <div class="screenshot-shape">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
            <img src="assets/img/shape/6.png" alt="shape">
        </div>
    </div>
</div>