import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sass-landing-one',
    templateUrl: './sass-landing-one.component.html',
    styleUrls: ['./sass-landing-one.component.scss']
})
export class SassLandingOneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}