<div class="standard-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="standard-text">
                    <div class="section-title text-start">
                        <span>Standard Code</span>
                        <h2>Generate By Standard Code</h2>
                    </div>
                    <p>With Euzan, code generation is the process by which a compiler's code generator converts some intermediate representation of source code into a form that can be readily executed by a machine.Sophisticated compilers typically perform multiple passes over various intermediate forms.</p>
                    <p>This multi-stage process is used because many algorithms for code optimization are easier to apply one at a time, or because the input to one optimization relies on the completed processing performed by another optimization.</p>
                    <div class="standard-btn">
                        <a routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="standard-img">
                    <img src="assets/img/sass-landing/laptop-two.png" alt="laptop">
                </div>
            </div>
        </div>
        <div class="standard-shape">
            <img src="assets/img/map-three.png" alt="shape">
        </div>
    </div>
</div>