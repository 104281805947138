<div id="showcase" class="show-case-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>My Latest Works As Showcase</h2>
            <p>App Store and Google Play Store gift cards are interchangeable, so no matter which one you received  you can use the credit for apps.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="showcase-card">
                    <img src="assets/img/personal-portfolio/showcase/1.jpg" alt="showcase image">
                    <div class="showcsae-text">
                        <h3>UX Case Study</h3>
                        <p>User experience is a person's emotions and attitudes about using a particular product, system or service.</p>
                    </div>
                    <div class="shoscase-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <h3>UX Case Study</h3>
                                <p>A Website content writer or web content writer is a person who specializes in
                                providing relevant content for websites. Every website has a specific target
                                audience and requires the most relevant content to attract business. Content should contain keywords aimed towards improving a website's SEO.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="showcase-card">
                    <img src="assets/img/personal-portfolio/showcase/2.jpg" alt="showcase image">
                    <div class="showcsae-text">
                        <h3>Video Editing</h3>
                        <p>User experience is a person's emotions and attitudes about using a particular product, system or service.</p>
                    </div>
                    <div class="shoscase-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <h3>Content Writing</h3>
                                <p>A Website content writer or web content writer is a person who specializes in
                                providing relevant content for websites. Every website has a specific target
                                audience and requires the most relevant content to attract business. Content should contain keywords aimed towards improving a website's SEO.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="showcase-card">
                    <img src="assets/img/personal-portfolio/showcase/3.jpg" alt="showcase image">
                    <div class="showcsae-text">
                        <h3>App Development</h3>
                        <p>Mobile app development is the act or process by which a mobile app is developed for mobile devices.</p>
                    </div>
                    <div class="shoscase-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <h3>App Development</h3>
                                <p>A Website content writer or web content writer is a person who specializes in
                                providing relevant content for websites. Every website has a specific target
                                audience and requires the most relevant content to attract business. Content should contain keywords aimed towards improving a website's SEO.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="showcase-card">
                    <img src="assets/img/personal-portfolio/showcase/4.jpg" alt="showcase image">
                    <div class="showcsae-text">
                        <h3>Content Writing</h3>
                        <p>User experience is a person's emotions and attitudes about using a particular product, system or service.</p>
                    </div>
                    <div class="shoscase-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <h3>Content Writing</h3>
                                <p>A Website content writer or web content writer is a person who specializes in
                                providing relevant content for websites. Every website has a specific target
                                audience and requires the most relevant content to attract business. Content should contain keywords aimed towards improving a website's SEO.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="showcase-card">
                    <img src="assets/img/personal-portfolio/showcase/5.jpg" alt="showcase image">
                    <div class="showcsae-text">
                        <h3>Web Development</h3>
                        <p>Mobile app development is the act or process by which a mobile app is developed for mobile devices.</p>
                    </div>
                    <div class="shoscase-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <h3>Web Development</h3>
                                <p>A Website content writer or web content writer is a person who specializes in
                                providing relevant content for websites. Every website has a specific target
                                audience and requires the most relevant content to attract business. Content should contain keywords aimed towards improving a website's SEO.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="showcase-card">
                    <img src="assets/img/personal-portfolio/showcase/6.jpg" alt="showcase image">
                    <div class="showcsae-text">
                        <h3>UI Design</h3>
                        <p>Mobile app development is the act or process by which a mobile app is developed for mobile devices.</p>
                    </div>
                    <div class="shoscase-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <h3>UI Design</h3>
                                <p>A Website content writer or web content writer is a person who specializes in
                                providing relevant content for websites. Every website has a specific target
                                audience and requires the most relevant content to attract business. Content should contain keywords aimed towards improving a website's SEO.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>