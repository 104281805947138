<div id="home" class="main-banner banner-style-three banner-bg-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-text">
                            <h1>The Best Creative Agency</h1>
                            <p>Landset is an creative or professional agency that helps you out. This firm is here to help you provide policy, production and advertisement services to your company's clients.</p>
                            <div class="banner-btn">
                                <a routerLink="/agency-landing-one" class="active">Get Started</a>
                                <a routerLink="/agency-landing-one">Contact Us</a>     
                            </div>
                        </div>
                    </div>
                    <div class="banner-img-three">
                        <img src="assets/img/creative-agency/1.png" alt="consultant image">
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>