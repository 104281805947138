import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-banner',
    templateUrl: './app-banner.component.html',
    styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}

}