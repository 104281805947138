import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sass-one-apps',
  templateUrl: './sass-one-apps.component.html',
  styleUrls: ['./sass-one-apps.component.scss']
})
export class SassOneAppsComponent implements OnInit {

  private urlZaicuRegisterProfesional = "https://zaicu.cl/registro/profesionales"

    constructor() {}

    ngOnInit(): void {}

	redirectTo(){
       window.location.href = this.urlZaicuRegisterProfesional;
	}

}
