<app-sass-one-banner></app-sass-one-banner>

<!--<app-sass-one-trusted-company></app-sass-one-trusted-company>-->

<!--<app-sass-one-services></app-sass-one-services>-->

<app-sass-one-why-choose></app-sass-one-why-choose>

<!--<app-sass-one-standard></app-sass-one-standard>-->

<app-sass-one-apps></app-sass-one-apps>

<!--<app-sass-one-counter></app-sass-one-counter>-->

<app-sass-one-pricing></app-sass-one-pricing>

<!--<app-sass-one-testimonial></app-sass-one-testimonial>-->