<div id="about" class="about-us-section about-style-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image text-center">
                    <img src="assets/img/creative-agency/about-two.png" alt="about image">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title text-start">
                        <span>About Us</span>
                        <h2>We Provide Excellent Business Solutions</h2>
                    </div>
                    <p>Landset is here to provide you with the best experience of creating a great venture. We help you to run your business with minimal tension.</p>
                    <ul>
                        <li><i class="flaticon-checked"></i> Creative Agency</li>
                        <li><i class="flaticon-checked"></i> 100% Trusted</li>
                        <li><i class="flaticon-checked"></i> 7 Years Experience</li>
                        <li><i class="flaticon-checked"></i> Top Ranking Agency</li>
                        <li><i class="flaticon-checked"></i> Best Support</li>
                    </ul>
                    <div class="about-btn">
                        <a routerLink="/agency-landing-two">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-shape">
        <img src="assets/img/map-two.png" alt="about shape">
    </div>
</div>