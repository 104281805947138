<div id="home" class="main-banner banner-style-three banner-bg-five">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-text">
                            <h1>THE ULTIMATE CREATIVE AGENCY</h1>
                            <p>So what is a creative agency. It is a company that offers a combination of strategy, design, technology and advertising services to clients, it is creative or expert lead.</p>
                            <div class="banner-btn">
                                <a routerLink="/agency-landing-two" class="active">Get Started</a>
                                <a routerLink="/agency-landing-two">Contact Us</a>     
                            </div>
                        </div>
                    </div>
                    <div class="banner-img-three">
                        <img src="assets/img/creative-agency/2.png" alt="consultant image">
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>