<div id="services" class="service-style-three pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Services I Provide for Clients</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-items wow fadeInUp" data-wow-duration="1s">
                    <i class="flaticon-ui"></i>
                    <h3>UIUX Design</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-items wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2">
                    <i class="flaticon-web-programming"></i>
                    <h3>Web Development</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-items wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3">
                    <i class="flaticon-medical-app"></i>
                    <h3>App Development</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-items wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4">
                    <i class="flaticon-writing"></i>
                    <h3>Conent Writing</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-items active wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5">
                    <i class="flaticon-java"></i>
                    <h3>Java Coding</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-items wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6">
                    <i class="flaticon-video"></i>
                    <h3>Video Editing</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
        </div>
    </div>
</div>