<div id="services" class="feature-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>App Features</span>
            <h2>Boost up with Our Great Features</h2>
            <p>Reserve your tickets for the trip you are planning to go on. Euzan gives you lots of great options that will let you have a safe and happy journey.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="feature-card wow fadeInUp" data-wow-duration="2s">
                    <i class="flaticon-layers"></i>
                    <h3>Easy To Use</h3>
                    <p>Euzan is very user-friendly. The app is easy to operate and reserve your ticket to your next.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="feature-card active wow fadeInUp" data-wow-duration="2s">
                    <i class="flaticon-clipboard"></i>
                    <h3>Online Booking</h3>
                    <p>Book your ticket online at the given price, and you don't need to go miles to buy your ticket.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-md-3 offset-lg-0">
                <div class="feature-card wow fadeInUp" data-wow-duration="2s">
                    <i class="flaticon-credit-card"></i>
                    <h3>Secured Payment</h3>
                    <p>You don’t have to worry about paying it in cash. We guarantee you it is secure online.</p>
                </div>
            </div>
        </div>
    </div>
</div>