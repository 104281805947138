<div id="testimonials" class="testimonial-style-two pb-100">
    <div class="container">
        <div class="section-title">
            <h2>People Quotes About Us</h2>
            <p>A comment is something that you say which expresses your opinion of something or which gives an explanation of it. If an event or situation.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 offset-lg-3">
                <div class="testimonial-slider-area">
                    <owl-carousel-o [options]="testimonialSlider">
                        <ng-template carouselSlide>
                            <div class="testimonial-items">
                                <div class="client-info">
                                    <img src="assets/img/app-landing/testimonial/client-img-three.png" alt="client">
                                    <h6>John Doe</h6>
                                    <p>Treaveller</p>
                                </div>
                                <p class="client-text">“I am really enjoying this app so far. I was sur rised that Shopify didn`t have a built in func ion for something like this. I am really enjoying this app so far.”</p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="testimonial-items">
                                <div class="client-info">
                                    <img src="assets/img/app-landing/testimonial/client-img-two.png" alt="client">
                                    <h6>Alice</h6>
                                    <p>Developer</p>
                                </div>
                                <p class="client-text">“I am really enjoying this app so far. I was sur rised that Shopify didn`t have a built in func ion for something like this. I am really enjoying this app so far.”</p>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>