<div id="apps" class="our-apps pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="our-apps-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/iphone.png" alt="iphone">
                </div>
            </div>
            <div class="col-lg-7">
                <div class="our-apps-text">
                    <div class="section-title text-start">
                        <span>Tambien en tu teléfono</span>
                        <h2>Aplicación responsiva</h2>
                    </div>
                    <p>Nuestra aplicación de agenda, que se adapta perfectamente a tus necesidades en cualquier dispositivo. Con un diseño responsivo, Zaicu garantiza una experiencia de usuario fluida y eficiente tanto en tu computadora de escritorio como en tu dispositivo móvil. Organiza tu tiempo de manera conveniente, estés donde estés, gracias a la interfaz adaptativa de Zaicu. ¡Tu agenda ahora siempre está al alcance de tus manos!</p>
                    <div class="our-apps-btn">
                        <a (click)="redirectTo()" class="active">
                            <h5>¡Comienza ya!</h5>
                        </a>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="apps-shape">
        <img src="assets/img/shape/1.png" alt="shape">
        <img src="assets/img/shape/3.png" alt="shape">
        <img src="assets/img/shape/5.png" alt="shape">
        <img src="assets/img/shape/6.png" alt="shape">
        <img src="assets/img/map-two.png" alt="shape">
    </div>
</div>