<div id="about" class="about-us-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image text-center">
                    <img src="assets/img/creative-agency/about.png" alt="about image">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title text-start">
                        <span>About Us</span>
                        <h2>Best Online Service Provider</h2>
                    </div>
                    <p>Constructing a secure environment to bring the best of innovative and unique business involving your business. Our team of experts is always ready to give you the very best service. Manage your business online with our help</p>
                    <ul>
                        <li><i class="flaticon-checked"></i>Creative Agency</li>
                        <li><i class="flaticon-checked"></i>100% Trusted</li>
                        <li><i class="flaticon-checked"></i>7 Years Experience</li>
                        <li><i class="flaticon-checked"></i>Top Ranking Agency</li>
                        <li><i class="flaticon-checked"></i>Best Support</li>
                    </ul>
                    <div class="about-btn">
                        <a routerLink="/agency-landing-one">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-shape">
        <img src="assets/img/map-two.png" alt="about shape">
    </div>
</div>