import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sass-landing-two',
  templateUrl: './sass-landing-two.component.html',
  styleUrls: ['./sass-landing-two.component.scss']
})
export class SassLandingTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
