<div id="services" class="service-style-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>We Providing Our Best Services</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/1.png" alt="icon">
                    <h3>Business Marketing</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/2.png" alt="icon">
                    <h3>Expand SEO</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/3.png" alt="icon">
                    <h3>Office Management</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/4.png" alt="icon">
                    <h3>Client Managing</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/5.png" alt="icon">
                    <h3>Data Collection</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/6.png" alt="icon">
                    <h3>Secured System</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="service-shape">
        <img src="assets/img/map-four.png" alt="map shape">
        <img src="assets/img/sass-landing/shapes/3.png" alt="shape">
    </div>
</div>