<div id="home" class="main-banner banner-style-two banner-bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Agenda para profesionales</h1>
                    <p>¿Estás listo para simplificar tu vida y tomar el control de tu tiempo? ¡Bienvenido a nuestra página de agendamientos, donde la organización se encuentra con la comodidad! Sabemos que tu tiempo es valioso, y nuestra misión es hacer que cada minuto cuente. Con nuestra plataforma intuitiva y eficiente, nunca ha sido tan fácil programar tus citas, reuniones y eventos.</p>
                    <div class="banner-btn" style="display: none;">
                        <a (click)="redirectTo()">¡Comienza ya!</a>
                        <!-- <button class="banner-video" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="flaticon-play-button"></i> Watch Video</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
    <div class="banner-image-two" >
        <img src="assets/img/sass-landing/laptop.png" alt="laptop">
        <img src="assets/img/sass-landing/iphone.png" alt="iphone">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>
    <div class="banner-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>