<div id="home" class="main-banner banner-style-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-text">
                            <span>Hello</span>
                            <h1>I’m Amelia Anderson</h1>
                            <h6>UI/UX Designer</h6>
                            <p>This is my homepage, so I have to say something about myself. Sometimes it is hard to introduce yourself because you know yourself so well that you do not</p>
                            <div class="banner-btn">
                                <a routerLink="/portfolio-landing">Lets Talk!</a>
                                <a routerLink="/portfolio-landing" class="cv-btn">Download CV</a>     
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1">
                        <div class="banner-img-two">
                            <img src="assets/img/personal-portfolio/1.png" alt="iphone">
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
    <div class="banner-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
    </div>
</div>