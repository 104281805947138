<app-app-banner></app-app-banner>

<app-app-why-choose></app-app-why-choose>

<app-app-features></app-app-features>

<app-app-screenshots></app-app-screenshots>

<app-app-how-to-use></app-app-how-to-use>

<app-app-pricing></app-app-pricing>

