<div id="about" class="about-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-4 p-0">
                <div class="about-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/personal-portfolio/2.png" alt="about image">
                    <div class="about-img-text">
                        <p>Amelia Anderson</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-behance"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-globe"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="about-text wow fadeInUp" data-wow-duration="1s">
                    <h2>A Little Brief About Myself</h2>
                    <p>My name is Amelia Anderson. I live in New York city. I am a UIUX     designer. UI, or user interface, focuses on the look and layout. A UX designer
                    ensures a product makes sense to the user by creating a path that logically flows from one step to the next. A UI designer ensures each page visually communicates that path. And I have 10 years Experience.</p>
                    <ul>
                        <li><i class="flaticon-email"></i>amelia@landset.com</li>
                        <li><i class="flaticon-maps-and-flags"></i>28/A Street, New York, USA</li>
                        <li><i class="flaticon-call"></i>+13028580567</li>
                        <li><i class="flaticon-globe"></i>www.domain.com</li>
                        <li><i class="flaticon-flag"></i>North American</li>
                        <li><i class="flaticon-work-from-home"></i>Available Freelancer</li>
                    </ul>
                    <div class="about-btn">
                        <a routerLink="/portfolio-landing">Hire Now</a>
                        <a routerLink="/portfolio-landing">See My Work</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-shape">
        <img src="assets/img/map-two.png" alt="map">
    </div>
</div>