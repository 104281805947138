<div id="about" class="why-choose why-choose-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span>Nuestros servicios</span>
            <h2>Sistema de agendamiento de horas para profesionales</h2>
        <br/>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/laptop.png" alt="why choose image">
                </div>
            </div>
            <div class="col-lg-5">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="1s">
                    <div class="media d-flex">
                        <i class="flaticon-computer mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Interfaz de usuario intuitiva.</h3>
                            <p>Descubre la comodidad de una interfaz de usuario bien organizada, que hace que encontrar diferentes herramientas y opciones sea pan comido.</p>
                        </div>
                    </div>
                   
                    <div class="media d-flex">
                        <i class="flaticon-countdown mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Mejor rendimiento</h3>
                            <p>
                                Experimenta un rendimiento excepcional con Zaicu. Nuestra aplicación garantiza un desempeño óptimo en todas tus actividades.</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="fi fi-sr-time-past mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Historial de sesiones</h3>
                            <p>
                               Revisa en todo momento todas tus sesiones pendientes, realizadas, etc... Además de poder agregar comentarios y recordatorios.</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="fi fi-rr-notebook-alt mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Agenda de sesiones</h3>
                            <p>Puedes agendar facilmente tus citas online con google meet gracias a nuestra integración con google, con alguna otra plataforma online de tu preferencia o agendar tus citas presenciales.</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="fi fi-rr-notebook-alt mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Notificación por correo</h3>
                            <p>Notificamos via correo electronico cuando se agenda una hora. y tambien notificamos antes de que inicie la sesión.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shapes">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
            <img src="assets/img/shape/6.png" alt="shape">
            <img src="assets/img/shape/7.png" alt="shape">
        </div>
    </div>
</div>